import React from 'react'
import { Link } from 'gatsby'

import Seo from '../components/SEO/Seo'

export default function ErrorPage({location}) {

	return (
		<>
			<Seo title="404 - Page Not Found" description="Sorry, the page you are looking for does not exist. Please check the URL and try again." pathname={ location.pathname } />

			<div className="error-page">
				<div className="error-page__wrapper wrapper">
					<h1 className="error-page__heading">404 PAGE NOT FOUND</h1>
					<p className="error-page__sub-heading">Oops! The page that you're looking for has been removed or relocated.</p>

					<Link
						to="/"
						className="error-page__button button">
							Return Home
					</Link>
				</div>
			</div>
		</>
	)
}